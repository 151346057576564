.right-click-menu {
  position: fixed;
  background-color: white;
  border: 1px solid #ccc;
  border-top: 1px solid #f0f0f0;
  border-radius: 5px;
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.2);
  padding: 5px 0;
  z-index: 1000;
}

.right-click-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.right-click-menu li {
  padding: 8px 16px;
  cursor: pointer;
}

.right-click-menu li:hover {
  background-color: #f0f0f0;
}

@media (prefers-color-scheme: dark) {
  .right-click-menu {
    background-color: #333;
    color: white;
    border-color: #555;
  }

  .right-click-menu li:hover {
    background-color: #555;
  }
}
