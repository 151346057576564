.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.pagination-button {
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #1a202c;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-button:hover:not(:disabled) {
  background-color: #f7fafc;
  border-color: #cbd5e0;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: #3b82f6;
  border-color: #3b82f6;
  color: #ffffff;
}

.pagination-ellipsis {
  padding: 0 0.5rem;
  color: #4a5568;
}

/* Dark theme support */
@media (prefers-color-scheme: dark) {
  .pagination-button {
    background-color: #1a202c;
    border-color: #4a5568;
    color: #e2e8f0;
  }

  .pagination-button:hover:not(:disabled) {
    background-color: #2d3748;
    border-color: #718096;
  }

  .pagination-button.active {
    background-color: #4299e1;
    border-color: #4299e1;
    color: #ffffff;
  }

  .pagination-ellipsis {
    color: #a0aec0;
  }
}

/* Focus states for accessibility */
.pagination-button:focus {
  outline: 2px solid #3b82f6;
  outline-offset: 2px;
}

@media (prefers-color-scheme: dark) {
  .pagination-button:focus {
    outline-color: #4299e1;
  }
}

/* High contrast mode support */
@media (forced-colors: active) {
  .pagination-button {
    border: 1px solid ButtonBorder;
  }

  .pagination-button.active {
    forced-color-adjust: none;
    background-color: Highlight;
    color: HighlightText;
  }
}
