.dev-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: repeating-linear-gradient(
    45deg,
    #e0e400,
    #e0e400 10px,
    #29250e 10px,
    #29250e 20px
  );
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 10px;
  font-weight: bold;
  z-index: 1000;
}

.dev-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  z-index: -1;
}
