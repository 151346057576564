.login-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.login-card {
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

h2 {
  margin-bottom: 20px;
}

.login-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.login-button i {
  margin-right: 10px;
}

.login-button.apple {
  background-color: black;
}

.login-button.google {
  background-color: #db4437;
}

.login-button.facebook {
  background-color: #4267b2;
}

.login-button.microsoft {
  background-color: #00a2ed;
}
