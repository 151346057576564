.grid-container {
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.grid-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 1rem;
}

@media (max-width: 1200px) {
  .image-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 992px) {
  .image-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 576px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 400px) {
  .image-grid {
    grid-template-columns: 1fr;
  }
}

.image-item {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.image-item.selected {
  outline: 2px solid #3b82f6;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.selection {
  position: fixed;
  border: 2px solid blue;
  background-color: rgba(0, 0, 255, 0.1);
  pointer-events: none;
}

@media (prefers-color-scheme: dark) {
  .grid-title {
    color: #ffffff;
  }

  .image-item {
    box-shadow: 0 4px 6px -1px rgba(255, 255, 255, 0.1),
      0 2px 4px -1px rgba(255, 255, 255, 0.06);
  }

  .image-item.selected {
    outline: 2px solid #60a5fa;
  }
}
