/* Basic styling for the sidebar navigation button */
.sidebar-button {
  display: block;
  width: 100%;
  padding: 12px 20px;
  text-decoration: none;
  text-align: left;
  color: #ffffff; /* Text color */
  background-color: #202020; /* Dark background color */
  border: 0.5px solid; /* Thin border */
  border-color: #727272;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

/* Hover effect */
.sidebar-button:hover {
  background-color: #555555; /* Darker background color on hover */
  border-color: #ffffff; /* Border color on hover */
}

/* Active/current page styling */
.sidebar-button.active {
  background-color: #555555; /* Darker background color for active/current page */
  border-color: #ffffff; /* Border color for active/current page */
}
