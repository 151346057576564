/* src/Toolbar.css */
.toolbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #c5c5c57e;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
  z-index: 1000; /* Ensures the toolbar stays above other elements */
}

.toolbar-title h5 {
  margin: 0;
}

.toolbar-icons button {
  color: #000;
}

@media (prefers-color-scheme: dark) {
  .toolbar {
    border-top-color: #555;
    border-bottom-color: #555;
    color: #fff;
    background-color: #070813c7;
  }

  .toolbar-icons button {
    color: #fff;
  }
}
