.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  gap: 5px; /* No gaps between images */
  margin-left: 5px;
  margin-right: 5px;
}

.photo-grid img {
  width: 100%;
  height: 128px;
  object-fit: cover;
  border-radius: 15%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.photo-grid img:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.photo-grid img.selected {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* More prominent shadow for selected images */
  border: 2px solid #007bff; /* Add border to indicate selection */
}

.year {
  margin-top: 20px;
  margin-bottom: 0px;
}

@media (prefers-color-scheme: dark) {
  .year {
    color: white;
  }
}

/* Layout */
.layout-wrapper {
  display: flex;
  flex-direction: column;
}

.layout-container {
  display: flex;
  flex: 1;
}

.layout-sidebar {
  flex: 0 0 200px;
  background-color: #e5d2ff;
  border-right: 1px solid #ccc;
  position: fixed;
  width: 200px;
  height: calc(100vh - 50px);
}

.layout-content {
  flex: 1;
  margin-left: 200px;
}

@media (prefers-color-scheme: dark) {
  .layout-sidebar {
    background-color: #2d3748;
    border-right: 1px solid #555;
  }

  .layout-content {
    background-color: #1a202c;
  }
}
